.grid-wrapper {
  padding-bottom: 1rem;

  &.first-in-list {
    padding-top: 1.5rem;
  }

  &.last-in-list {
    padding-bottom: 3rem;
  }
}

.grid {
  gap: 1rem;
  list-style: none;
  display: grid;
  margin: 0 auto;
  max-width: 72.75rem;
  padding: 0 1.5rem;
}

.large-to-small,
.small-to-large {
  grid-template-areas:
    'large large'
    'small1 small2';
  grid-template-columns: 1fr 1fr;
}

.large {
  grid-area: large;
  position: relative;
  aspect-ratio: 4/3;
}

.small1 {
  grid-area: small1;
  position: relative;
  aspect-ratio: 5/4;
}

.small2 {
  grid-area: small2;
  position: relative;
  aspect-ratio: 5/4;
}

.image {
  object-fit: cover;
}

@media screen and (min-width: 769px) {
  .large-to-small {
    grid-template-areas:
      'large small1'
      'large small2';
    grid-template-columns: 7fr 3fr;
  }

  .small-to-large {
    grid-template-areas:
      'small1 large'
      'small2 large';
    grid-template-columns: 3fr 7fr;
  }

  .small1,
  .small2 {
    aspect-ratio: unset;
  }
}

@media screen and (min-width: 1024px) {
  .grid-wrapper {
    padding-bottom: 2rem;

    &.first-in-list {
      padding-top: 2rem;
    }

    &.last-in-list {
      padding-bottom: 4rem;
    }
  }

  .grid {
    gap: 2rem;
    padding: 0rem 2rem;
  }
}
