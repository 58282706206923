.webpage-template * {
  font-family: 'acumin-pro', Helvetica, Arial, sans-serif;
}

.webpage-template {
  .preFooterCta > div {
    margin-top: 0;
    padding-top: 32px;
  }

  .preFooterCta :global(.cta-container) {
    margin: 0 auto;
  }
  .preFooterCta :global(.cta-container .cta-position-fullwidth) {
    padding-bottom: 0;
  }
  .preFooterCta :global(.cta-container .cta-position-fullwidth .cta) {
    border-radius: 0px;
    margin: 0px auto;
    min-height: 378px;
  }
  .preFooterCta
    :global(.cta-container .cta-position-fullwidth .cta .cta-inside) {
    width: 95%;
  }
  .preFooterCta
    :global(
      .cta-container .cta-position-fullwidth .cta .cta-inside .cta-heading
    ) {
    font-size: 26px;
    line-height: 31px;
  }
  .preFooterCta
    :global(
      .cta-container .cta-position-fullwidth .cta .cta-inside .cta-buttons
    ) {
    margin-top: 35px;
  }
  @media screen and (min-width: 425px) {
    .preFooterCta
      :global(.cta-container .cta-position-fullwidth .cta .cta-inside) {
      padding: 120px 0px 30px;
    }
  }
  @media screen and (min-width: 769px) {
    .preFooterCta
      :global(.cta-container .cta-position-fullwidth .cta .cta-inside) {
      max-width: 96%;
    }
    .preFooterCta :global(.cta-container .cta-position-fullwidth .cta) {
      min-height: 418px;
    }
    .preFooterCta
      :global(
        .cta-container .cta-position-fullwidth .cta .cta-inside .cta-heading
      ) {
      font-size: 32px;
      line-height: 38px;
    }
    .preFooterCta
      :global(
        .cta-container .cta-position-fullwidth .cta .cta-inside .cta-buttons
      ) {
      margin-top: 48px;
    }
  }
  @media only screen and (min-width: 1680px) {
    .preFooterCta
      :global(.cta-container .cta-position-fullwidth .cta .cta-inside) {
      padding: 160px 0px 0px;
    }
  }
}
