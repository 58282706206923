.hero-image-desktop-wrapper,
.hero-image-mobile-wrapper {
  height: 100%;
  position: relative;
}

.hero-image-desktop,
.hero-image-mobile {
  object-fit: cover;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  height: 100%;
}
.iframe-container :global iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
