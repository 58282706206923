/* label */
.label :global(.markdown *) {
  color: var(--label-text-color);
  text-align: var(--align-text);
  text-shadow: var(--hero-text-shadow);
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}
.label :global(.markdown h1) {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
}
.label :global(.markdown h2) {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
.label :global(.markdown h3) {
  font-size: 18px;
  line-height: 29px;
  font-weight: 700;
}
.label :global(.markdown b) {
  font-weight: 700;
}

/* header */
.header :global(.markdown *) {
  color: var(--header-text-color);
  text-align: var(--align-text);
  text-shadow: var(--hero-text-shadow);
  font-size: 25px;
  line-height: 30px;
  font-weight: 400;
}
.header :global(.markdown h1) {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}
.header :global(.markdown h2) {
  font-size: 34px;
  line-height: 40px;
  font-weight: 700;
}
.header :global(.markdown h3) {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
}
.header :global(.markdown b) {
  font-weight: 700;
}

/* intro */
.intro :global(.markdown *) {
  color: var(--intro-text-color);
  text-align: var(--align-text);
  text-shadow: var(--hero-text-shadow);
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}
.intro :global(.markdown h1) {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
}
.intro :global(.markdown h2) {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
.intro :global(.markdown h3) {
  font-size: 18px;
  line-height: 29px;
  font-weight: 700;
}
.intro :global(.markdown b) {
  font-weight: 700;
}
.intro {
  padding-top: 16px;
}

.inner {
  height: var(--mobile-hero-height);
  overflow: hidden;
  position: relative;
}

.hero-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 24px;
}

.hero-section-links {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  gap: 16px;

  /* for Widen video - added so section link buttons could get clicks */
  pointer-events: auto;
}

.hero-section-links :global a {
  font-family: 'source-sans-pro', sans-serif;
  text-decoration: none;
}

.overlay {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  /* for Widen video - added so video could get clicks (play, pause, volume) */
  pointer-events: none;
}

.bottom-left {
  top: 45% !important;
}

@media screen and (min-width: 768px) {
  .overlay {
    top: 0;
  }
  .right {
    left: 40%;
  }
  .left {
    right: 50%;
  }
  .bottom-left {
    top: 45%;
    right: 50%;
  }
  .center {
    width: 50%;
    left: 25%;
  }
  .hero-container {
    margin: auto 48px;
  }
  .line {
    margin: 16px 0;
  }

  /* label */
  .label :global(.markdown *) {
    color: var(--label-text-color);
    text-align: var(--align-text);
    font-size: 18px;
    line-height: 29px;
    font-weight: 400;
  }
  .label :global(.markdown h1) {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
  }
  .label :global(.markdown h2) {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
  }
  .label :global(.markdown h3) {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
  .label :global(.markdown b) {
    font-weight: 700;
  }

  /* header */
  .header :global(.markdown *) {
    color: var(--header-text-color);
    font-size: 34px;
    line-height: 40px;
    font-weight: 400;
  }
  .header :global(.markdown h1) {
    font-size: 60px;
    line-height: 68px;
    font-weight: 700;
  }
  .header :global(.markdown h2) {
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
  }
  .header :global(.markdown h3) {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
  }
  .header :global(.markdown b) {
    font-weight: 700;
  }

  /* intro */
  .intro :global(.markdown *) {
    color: var(--intro-text-color);
    font-size: 18px;
    line-height: 29px;
    font-weight: 400;
  }
  .intro :global(.markdown h1) {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
  }
  .intro :global(.markdown h2) {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
  }
  .intro :global(.markdown h3) {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
  .intro :global(.markdown b) {
    font-weight: 700;
  }
}

@media screen and (min-width: 1200px) {
  .inner {
    height: var(--hero-height);
  }

  .hero-container {
    margin: auto 90px;
  }
}
